<template>
    <base-backdrop>
        <loading-spinner />
    </base-backdrop>
</template>

<script>
import BaseBackdrop from './BaseBackdrop.vue'
import LoadingSpinner from './LoadingSpinner.vue'
export default {
    components: {BaseBackdrop, LoadingSpinner},
    setup() {
        
    },
}
</script>