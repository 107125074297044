<template>
    <div class="dashboard-container container-fluid">
      <div style="margin-bottom: 20px;" class="top-buttons-div">
        <form @submit.prevent="fetchProducts(1)" class="form-inline d-flex my-2 my-md-0">
            <div class="search-field-wrapper mr-sm-2 me-2 align-items-center">
              <input
                class="form-control search-field"
                type="search"
                placeholder="Pretraga sajta..."
                aria-label="Search"
                @input="onSearch"
                v-model="searchValue"
              />
            </div>
            <base-button :buttonText="'Pretraži'" type="submit"></base-button>
          </form>
        <button 
            class="btn new-artical-btn" 
            @click="redirectToEdit(0)"
        >
            <svg class="add-icon"></svg>
            Novi artical
        </button>
      </div>
      <div>
            <div class="filter-icons">
                <h3>
                <svg class="filters-icon" @click="setFiltersAreOpen(true)" ></svg
                >Filteri i sortiranje
                </h3>
            </div>
        </div>
        <BusyLoader v-if="isBusy" />
        <div class="dashboard">
            <div class="filters">
                <AdminFilters 
                    :filters=filters
                    @sort="sortProducts($event)"
                    @action="showActionProducts($event)"
                    @category="showCategory($event)"
                    @selectAllCategories="selectAllCategories()"
                    @showDeleted="onShowDeletedProducts($event);"
                />
            </div>
            <div class="container-fluid">
                <div class="products">
                    <div class="grid">
                        <AdminProductItem
                            v-for="product in productsList"
                            :key="product.id"
                            :product="product"
                            :isDeleted="showDeleted"
                            @delete=deleteProduct(product.id)
                            @restore="restoreProduct(product.id)"
                        />
                    </div>
                    <BasePagination
                        @change="changePage"
                        :currentPage="currentPage"
                        :totalPages="numberOfPages"
                        style="margin-left: 110px; margin-top: 40px;"
                />
                </div>
            </div>
        </div>
    </div>
    <div class="aside-filters" v-if="filtersAreOpen">
        <BaseBackdrop @clicked="setFiltersAreOpen(false)" />
        <SideDrawer @close="setFiltersAreOpen(false)" :isSideDrawerOpen="filtersAreOpen">
        <AdminFilters
            :filters=filters
            @sort="sortProducts($event)"
            @action="showActionProducts($event)"
            @category="showCategory($event)"
            @selectAllCategories="selectAllCategories()"
            @showDeleted="onShowDeletedProducts($event);"
        />
        </SideDrawer>
    </div>
</template>

<script>
import BaseButton from '../../../components/shared/BaseButton.vue'
import SideDrawer from '../../../components/shared/SideDrawer.vue'
import BaseBackdrop from '../../../components/shared/BaseBackdrop.vue'
import AdminFilters from './AdminFilters.vue'
import BasePagination from '../../../components/product/BasePagination.vue'
import BusyLoader from '../../../components/shared/BusyLoader.vue'
import AdminProductItem from './AdminProductItem.vue'
import { watch, ref, onMounted, inject } from 'vue';
import { useRoute, useRouter } from "vue-router";
import axios from 'axios';
export default {
    components: {AdminProductItem, BusyLoader, BasePagination, AdminFilters, BaseBackdrop, SideDrawer, BaseButton},
    setup() {
        const toast = inject('toast');
        const router = useRouter();
        const route = useRoute();
        const productsList = ref([]); 
        let isOnMountedFinished = false;
        const currentPage = ref(1);
        const isBusy = ref(false);
        const numberOfPages = ref(1);
        const filters = ref({categories: []});
        const filtersAreOpen = ref(false);
        const showDeleted = ref(false);
        const searchValue = ref('');

        watch(route, () => {
            if(isOnMountedFinished) {
                fetchProducts();
            }
        })

        onMounted(() => fetchProducts())

        const fetchProducts = () => {
            currentPage.value = +(route.query.page || 1);
            const query = getUrlQueryFromFilters() || '';
            isBusy.value = true;
            const controllerMethod = showDeleted.value ? "/deleted" : "";
            const token = localStorage.getItem('vodotermUser') && JSON.parse(localStorage.getItem('vodotermUser')).token;
            const headers = {
                headers: { Authorization: `Bearer ${token}` }
            };
            axios.get(`${process.env.VUE_APP_API_URL}/api/Products${controllerMethod}?PageIndex=${currentPage.value}&PageSize=20${query}`, headers)
            .then(res => {
                numberOfPages.value = Math.floor((res.data.count - 1) / 20) + 1;
                productsList.value = res.data.data;
                isBusy.value = false;
            })

            if(!isOnMountedFinished) {
                isOnMountedFinished = true;
            }
        }

        const changePage = (pageNumber) => {
            router.push({ path: '', query: {...route.query, page: pageNumber} })
            window.scrollTo(0, 0);
            // fetchProducts()
            // currentPage.value = pageNumber;
            //fetchProducts(pageNumber);
            // window.scrollTo(0, 0);
        }

        const deleteProduct = (id) => {
            isBusy.value = true;
            const token = localStorage.getItem('vodotermUser') && JSON.parse(localStorage.getItem('vodotermUser')).token;
            const headers = {
                headers: { Authorization: `Bearer ${token}` }
            };
            axios.delete(`${process.env.VUE_APP_API_URL}/api/Products/${id}`, headers)
            .then(() => {
                isBusy.value = false;
                toast.success("Proizvod je uspešno obrisan");
                fetchProducts();
            })
            .catch(() => {
                toast.error("Nastala je greška, proizvod nije obrisan");
            })
        }

        const restoreProduct = (id) => {
            isBusy.value = true;
            const token = localStorage.getItem('vodotermUser') && JSON.parse(localStorage.getItem('vodotermUser')).token;
            const headers = {
                headers: { Authorization: `Bearer ${token}` }
            }; 
            axios.post(`${process.env.VUE_APP_API_URL}/api/Products/restore/${id}`, {}, headers)
            .then(() => {
                isBusy.value = false;
                toast.success("Proizvod je povracen");
                fetchProducts();
            })
            .catch(() => {
                toast.error("Nastala je greska, proizvod nije povracen");
            })
        }

        const getUrlQueryFromFilters = () => {
            let query = '';
            if(filters.value.sort) {
                query = query + `&sort=${filters.value.sort}`;
            }

            if(filters.value.categories && filters.value.categories.length > 0) {
                if(filters.value.categories.length === 1) {
                    query += `&categoryId=${filters.value.categories[0]}`;
                } else {
                    query += `&categories=${filters.value.categories.join(',')}`;
                }
                
            }

            if(filters.value.action) {
                query += `&isAction=${filters.value.action}`;
            }

            if(searchValue.value) {
                query += `&search=${searchValue.value}`;
            }
            return query;
        }

        const sortProducts = (sort) => {
            filters.value.sort = sort;
            if(currentPage.value != 1) {
                changePage(1);
            } else {
                fetchProducts();
            }
            
        }

        const showActionProducts = (isAction) => {
            filters.value.action = isAction;
            if(currentPage.value != 1) {
                changePage(1);
            } else {
                fetchProducts();
            }
        }

        const showCategory = ({checked, categoryId}) => {
            if(!checked) {
                selectAllCategories();
            } else {
                filters.value.categories = [];
                filters.value.categories.push(categoryId);
                if(currentPage.value != 1) {
                    changePage(1);
                } else {
                    fetchProducts();
                }
            }
        }

        const selectAllCategories = () => {
            filters.value.categories = [];
            if(currentPage.value != 1) {
                changePage(1);
            } else {
                fetchProducts();
            }
        }

        const setFiltersAreOpen = (value) => {
            filtersAreOpen.value = value;
        }

        const redirectToEdit = (id = 0) => {
            router.push({ name: 'Edit', params: { id } })
        }

        const onShowDeletedProducts = (deletedInd) => {
            showDeleted.value = deletedInd;
            if(currentPage.value != 1) {
                changePage(1);
            } else {
                fetchProducts();
            }
        }

        return {
            isBusy,
            productsList,
            currentPage,
            numberOfPages,
            showDeleted,
            filters,
            filtersAreOpen,
            searchValue,
            changePage,
            deleteProduct,
            sortProducts,
            showActionProducts,
            showCategory,
            selectAllCategories,
            setFiltersAreOpen,
            redirectToEdit,
            onShowDeletedProducts,
            restoreProduct,
            fetchProducts
        }
    },
}
</script>

<style lang="scss" scoped>
.dashboard-container {
    padding: 150px $grid-margin-width;

    @include media("<=desktop-small") { 
        padding-top: 75px;
    }
}

.aside-filters {
    @include media(">=desktop-small") { 
        display: none;
    }
}

  .search-field-wrapper{
    position: relative;
  }

    .search-field {
      @include background-svg($search-icon);
      background-repeat: no-repeat;
      background-position: 10px center;
      padding-left: 42px;
      border-radius: 0px;
      box-shadow: none !important;
    }

.dashboard {
    @include media(">=desktop-small") { 
        display: grid;
        grid-template-columns: 1fr 5fr;
    }
  }

    .grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        column-gap: 22px;

        @include media("<=desktop-small") { 
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            column-gap: 17px;
        }

        @include media("<=tablet") {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            column-gap: 17px;
        }
    }

    .top-buttons-div {
        @include media(">=tablet") {
            display: flex;
            justify-content: space-between;
        }
    }

    .filter-icons {
        @include media(">=desktop-small") {
            display: none;
        }

        margin-bottom: 20px;
    }

    .filters {
        @include media("<desktop-small") {
            display: none;
        }
    }
    
    .filters-icon {
    cursor: pointer;
    width: 19px;
    height: 12px;
    @include background-svg($filters-icon);
    }

    .new-artical-btn {
        width: 166px;
        height: 40px;
        background: color(blue);
        color: white;
    }

    .new-artical-btn:hover {
        color: white;
    }

        .add-icon {
            cursor: pointer;
            width: 20px;
            height: 20px;
            @include background-svg($admin-add-icon);
        }
</style>